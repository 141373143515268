import _ from "lodash";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {
  FILTERS_MODULE,
  FilterStateType,
  initFilterStateActionType,
} from "@/store/filters/filtersTypes";

import {FilterModel} from "@proapteka/filters";


// Слайс для фильтров, разделен на основные, и скрытые
const initialState: FilterStateType = {
  requiredFilters: [], // Ключи обязательных для заполнения фильтров
  errors: {},
  filters: {}
}

const filtersSlice = createSlice({
  name: FILTERS_MODULE,
  initialState,
  reducers: {
    // Инициализация стейта для фильтра
    initFilterState: (state, {payload: {id, scope, defaultValue, label}}: PayloadAction<initFilterStateActionType>) => {
      const newFilter: FilterModel = {
        error: '',
        isLoading: false,
        selected: defaultValue,
        isTokenFilter: false,
        scope,
        label
      }
      state.filters = {...state.filters, [id]: newFilter}
    },

    setFilterValues: (state, {payload: {id, value}}: PayloadAction<{id: string, value: FilterModel['selected']}>) => {
      if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
      state.filters[id].selected = value
    },

    setFilterError: (state, {payload: {error, id}}:PayloadAction<{id: string, error: string}>) => {
      state.filters[id].error = error
    },

    clearAdditional: (state) => {
      state.filters = Object.entries(state.filters)
        .reduce((acc, [id, filter]) => {
          if (filter.scope === 'main')
            return {...acc, [id]: filter}
          else if (_.isArray(filter.selected))
            return {...acc, [id]: {...filter, selected: []}}
          else
            return {...acc, [id]: {...filter, selected: {}}}
        }, {})
    },

    setRequiredFilters: (state, {payload}: PayloadAction<string[]>) => {
      state.requiredFilters = payload
    },


    setFilterTokenType: (state, {payload: {id, value}}: PayloadAction<{id: string, value: boolean}>) => {
      if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
      state.filters[id].isTokenFilter = value
    },

    setSuccessFileFilterUpload: (state, {payload: {id, token, selectedIds}}: PayloadAction<{id: string, token: string, selectedIds: number[]}>) => {
      if (!(id in state.filters)) throw new Error(`Filter - ${id} is not defined`)
      const count = selectedIds.length
      state.filters[id].filterTokenState = {
        actualCount: count,
        totalCount: count,
        isError: false,
        isFileUploaded: true,
        token,
        selectedIds,
      }
    },

    updateTokenFilterIds: (state, {payload: {id, selectedIds}}: PayloadAction<{id: string, selectedIds: number[]}>) => {
      // if (!(id in state.filters && 'filterTokenState')) throw Error(`Filter - ${id} is not defined`)
      if (state.filters[id].filterTokenState)
        { // @ts-ignore
          state.filters[id].filterTokenState = {
                    ...state.filters[id].filterTokenState,
                    selectedIds,
                    actualCount: selectedIds.length
                  }
        }
    },

    resetFiltersSlice: () => initialState
  }
})

export default filtersSlice.reducer

export const {
  clearAdditional,
  setFilterTokenType,
  resetFiltersSlice,
  initFilterState,
  setFilterValues,
  setFilterError,
  setSuccessFileFilterUpload,
  updateTokenFilterIds
} = filtersSlice.actions