import {
  combineReducers,
  configureStore,
  ConfigureStoreOptions,
  createListenerMiddleware,
  ListenerEffectAPI, TypedAddListener, TypedStartListening
} from "@reduxjs/toolkit";
import {apiSlice} from "@/store/apiSlice";
import {TypedUseSelectorHook, useSelector} from "react-redux";
import tableSettings from '@/store/table/tableSlice'
import appSettingsSlice from '@/store/appSettings/appSettingsSlice'
import filterSlice from '@/store/filters/filtersSlice'
import userSlice from '@/store/user/userSlice'
import notificationsSlice from '@/store/notifications/notificationsSlice'


export const rootReducer = combineReducers({
  tableSettings,
  appSettingsSlice,
  filterSlice,
  userSlice,
  notificationsSlice,
  [apiSlice.reducerPath]: apiSlice.reducer
})

const listenerMiddlewareInstance = createListenerMiddleware({
  onError: () => console.error
})

export const createStore = (options?: ConfigureStoreOptions['preloadedState'] | undefined) =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().prepend(listenerMiddlewareInstance.middleware).concat(apiSlice.middleware),
    ...options,
    devTools: true
  })



export const store = createStore()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const startAppListening = listenerMiddlewareInstance.startListening as AppStartListening

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>

export type AppListenerEffectAPI = ListenerEffectAPI<RootState, AppDispatch>
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
export type AppAddListener = TypedAddListener<RootState, AppDispatch>