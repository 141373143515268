import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import {passportApiUrl} from "@/env.config";

const baseQuery = fetchBaseQuery({
  baseUrl: passportApiUrl,
  prepareHeaders: (headers) => headers,
  credentials: 'include',
})

export const apiSlice = createApi({
  baseQuery,
  reducerPath: 'splitApi',
  tagTypes: ['Roles', 'UserRoles', 'ClaimCategories', 'PersonalClaim', 'AllUserClaims', 'RoleClaims'],
  endpoints: () => ({}),

})
