import {FilterModel} from "@proapteka/filters";

export const FILTERS_MODULE = 'FILTERS'


export type FilterStateType = {
  errors: Record<string, string>
  requiredFilters: string[]
  filters: Record<string, FilterModel>
}

export type initFilterStateActionType = {
  scope: string,
  id: string,
  defaultValue: FilterModel['selected'],
  label: string
}
