import {TableSettingsModel, TableStateType} from "@/store/table/tableTypes";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";

export const TABLE_SETTINGS_MODULE = 'TABLE_SETTINGS'


const initialState: TableStateType = {
  tables: {}
}

const tableSlice = createSlice({
  name: TABLE_SETTINGS_MODULE,
  initialState,
  reducers: {
    // Инициализировать модель таблицы
    initTable: (state, {payload}: PayloadAction<Record<number, TableSettingsModel>>) => {
      state.tables = {...state.tables, ...payload}
    },
    resetTableSlice: () => initialState,
    setColumnSizing: (state, {payload: {columnSizing, tableId}}: PayloadAction<{tableId: number, columnSizing: Record<string, number>}>) => {
      state.tables[tableId] = {...state.tables[tableId], columnSizing}
    },
    updateColumnSizing: (state, {payload: {columnSizing, tableId}}: PayloadAction<{tableId: number, columnSizing: Record<string, number>}>) => {
      state.tables[tableId].columnSizing = {...state.tables[tableId].columnSizing, ...columnSizing}
    },
    setColumnVisibility: (state, {payload: {columnVisibility, tableId}}: PayloadAction<{tableId: number, columnVisibility: Record<string, boolean>}>) => {
      state.tables[tableId] = {...state.tables[tableId], columnVisibility}
    },
    updateColumnVisibility: (state, {payload: {columnVisibility, tableId}}: PayloadAction<{tableId: number, columnVisibility: Record<string, boolean>}>) => {
      state.tables[tableId].columnVisibility = {...state.tables[tableId].columnVisibility, ...columnVisibility}
    },
    setColumnOrder: (state, {payload: {columnOrder, tableId}}: PayloadAction<{tableId: number, columnOrder: string[]}>) => {
      state.tables[tableId] = {...state.tables[tableId], columnOrder}
    },
    updateColumnOrder: (state, {payload: {columnOrder, tableId}}: PayloadAction<{tableId: number, columnOrder: string[]}>) => {
      state.tables[tableId].columnOrder = columnOrder
    },
    setFetched: (state, {payload: {tableId, isFetched}}: PayloadAction<{tableId: number, isFetched: boolean}>) => {
      state.tables[tableId] = {...state.tables[tableId], isFetched}
    },
    setIsNow: (state, {payload: {isNow, tableId}}: PayloadAction<{tableId: number, isNow: boolean}>) => {
      state.tables[tableId] = {...state.tables[tableId], isNow}
    },
  }
})

export default tableSlice.reducer

export const {
  initTable,
  setColumnSizing,
  setColumnVisibility,
  setColumnOrder,
  setFetched,
  setIsNow,
  updateColumnOrder,
  updateColumnVisibility,
  updateColumnSizing,
  resetTableSlice
} = tableSlice.actions