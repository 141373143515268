import axios from 'axios'
import {passportApiUrl} from '@/env.config'
// @ts-ignore
import qs from "qs"
import {OIDCAuthController} from "@proapteka/oidc-auth-controller";


const axiosInstance = axios.create({
  baseURL: passportApiUrl,
  timeout: 60 * 1000,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
  transformRequest: [(data) => JSON.stringify(data)],
  paramsSerializer: params => qs.stringify(params, {arrayFormat: 'repeat'})
})

const oAuthControllerInstance = new OIDCAuthController({Axios: axiosInstance})


export {
  axiosInstance,
  oAuthControllerInstance
}